// Content
$(function () {
  if (typeof noTdUtils === 'undefined') {
    $.ajax(`/a/timeDatePreferences`).done(async ([r]) => {
      if (!r) {
        return
      }

      window.localStorage.setItem(
        'dateTimePreferences',
        JSON.stringify({
          timezone: r.Preferences.timezone,
          dateFormat: r.Preferences.dateFormat,
        })
      )
    })
  }

  // Initialise Select2 on all select inputs.
  $(
    'select:not(.select2-hidden-accessible):not(.not-select2):not(.direct-select2)'
  ).select2()

  $('#toggle-menu__input').change(function () {
    $('.main-navigation__list').toggleClass('show')
  })

  $('#close-list').click(function () {
    $('#toggle-menu__input').prop('checked', false)
  })

  $('.launch-option.menu, .nav-hide-menu').click(function (event) {
    $('.main-navigation').toggleClass('show')
  })

  $('.launch-option.basket').click((event) => {
    AnyBasket.inst.reloadContent()
    $('.basket-notice').hide()
    $('#basket__slide').toggleClass('show')
  })

  var el = $('<div class="dropdown__preventDefault"></div>')

  $('.nav__list-item--parent').prepend(el.clone())

  $('.dropdown__preventDefault').click(function () {
    $(this).parent().find('.nav__list-sublist').slideToggle()
  })

  $('#onpage_support_button,.onpage_support_close-button').click(function () {
    $('.onpage_support').fadeToggle(250)
    setTimeout(function () {
      $('.dlg-head-srch__input').focus()
    }, 400)
  })

  $('#vps-power__button,.vps-power__list__close-button').click(function () {
    $('.vps-power__list').fadeToggle()
    $('.overlay.generic').fadeToggle()
  })

  $('#terms-modal__link, #terms-modal__close-button, .overlay.generic').click(
    function () {
      $('#terms-modal').fadeToggle([200])
      $('.overlay.generic').fadeToggle([200])
    }
  )

  $(
    '#ip-confirm-btn, #ip-confirm-btn2, #modal-standard__ip-confirm-close'
  ).click(function () {
    $('#modal-ip-confirm').fadeToggle()
    $('.overlay.generic').fadeToggle()
  })

  function stayLoggedIn() {
    $(document).off('keyup.logout')
    $(
      '.overlay.generic, #logout-options, .vps-power__list, #modal-ip-confirm, .add-services__list'
    ).fadeOut()
  }

  $('#logout-options, #logout-button').click(() => {
    $('#logout-options, .overlay.generic').fadeIn()
    $(document).one('keyup.logout', (event) => {
      if (event.keyCode == 13) {
        if (window.sessionStorage) sessionStorage.clear()

        const default_logout_url = SharedSettings.inst.isOpen
          ? '/basket-summary-login'
          : '/login'

        location.href = `/logout?r=${logoutUrl || default_logout_url}`
      } else if (event.keyCode == 27) {
        stayLoggedIn()
      }
    })
  })
  $('#log-me-out').click(() => {
    if (typeof SharedSettings == 'undefined') {
      // Not HS
      location.href = '/logout'
    } else {
      if (window.sessionStorage) sessionStorage.clear()

      const default_logout_url = SharedSettings.inst.isOpen
        ? '/basket-summary-login'
        : '/login'

      location.href = `/logout?r=${logoutUrl || default_logout_url}`
    }
  })
  $(document).on('click', '.overlay.generic, #stay-logged-in', stayLoggedIn)

  var iScrollPos = 0
  $(window).scroll(function () {
    var iCurScrollPos = $(this).scrollTop()
    var basketNotice = $('.basket-notice')
    if (iCurScrollPos < 80) {
      var diff = 80 - iCurScrollPos
      $(basketNotice).css('top', iCurScrollPos + diff + 'px')
    } else {
      $(basketNotice).css('top', iCurScrollPos + 10 + 'px')
    }
    iScrollPos = iCurScrollPos
  })

  $(document).on('click', '.show-basket-hide-notice', function (event) {
    event.preventDefault()
    $('.basket-notice').hide()
    $(window).scrollTop(0)
    $('#basket__slide').addClass('show')
  })

  if ($('#scroll-top')) {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 400) {
        $('#scroll-top').css('opacity', '1')
      } else {
        $('#scroll-top').css('opacity', '0')
      }
    })
  }

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
})

function autotable() {
  /** @type {NodeListOf<HTMLTableElement>} */
  const tables = document.querySelectorAll('table[data-autotable]')
  for (const t of tables) {
    delete t.dataset.autotable
    t.classList.add('table')
    const headers = t.querySelectorAll('thead > tr > th')
    const header_labels = []
    for (const h of headers) {
      header_labels.push(h.textContent)
    }
    /** @type {NodeListOf<HTMLTableRowElement>} */
    const rows = t.querySelectorAll('tbody > tr')
    for (const r of rows) {
      if (r.dataset['skip-autotable']) {
        delete r.dataset['skip-autotable']
      } else {
        /** @type {NodeListOf<HTMLTableCellElement>} */
        const cells = r.querySelectorAll('td, th')
        for (let i = 0; i < cells.length; i++) {
          cells[i].dataset.title = header_labels[i]
        }
      }
    }
  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class Site {
  /**
   * @type {?boolean}
   */
  static get translateEnabled() {
    if (localStorage.hasOwnProperty('googleTranslate')) {
      return !!+localStorage.googleTranslate
    } else {
      return null
    }
  }
  static set translateEnabled(v) {
    if (v && !this.translateEnabled) {
      this.translate().then(() => $('#google_translate_element').show())
    }
    if (v === null) {
      delete localStorage.googleTranslate
    } else {
      localStorage.googleTranslate = +v
    }
  }
  /**
   * @param {{$nextTick(Function)}} vue
   * @param {{[selector: string]: {act: () => void, oldOffset?: number}}} markers
   */
  static checkInfiniteScroll(vue, markers) {
    let existing_offset
    const scroll_top = $(window).scrollTop()
    const window_height = $(window).height()
    /**
     * @type {typeof markers}
     */
    const recheck = {}
    for (const [selector, config] of Object.entries(markers)) {
      if (
        (existing_offset = $(selector).offset()) &&
        (!config.oldOffset || existing_offset.top > config.oldOffset) &&
        scroll_top !== undefined &&
        window_height &&
        scroll_top + window_height > existing_offset.top
      ) {
        config.act()
        recheck[selector] = Object.assign({}, config, {
          oldOffset: existing_offset.top,
        })
      }
    }
    if (Object.keys(recheck).length) {
      vue.$nextTick(() => this.checkInfiniteScroll(vue, recheck))
    }
  }
  /**
   * @param {Function} scroll_listener
   */
  static deinitInfiniteScroll(scroll_listener) {
    window.removeEventListener('scroll', scroll_listener)
  }
  static hidePage() {
    document.body.style.opacity = '0'
  }
  /**
   * @param {{$nextTick(Function)}} vue
   * @param {{[selector: string]: {act: () => void, oldOffset?: number}}} markers
   * @return {Function} the function listening to the scroll event, so you can
   * remove it
   */
  static initInfiniteScroll(vue, markers) {
    vue.$nextTick(() => Site.checkInfiniteScroll(vue, markers))
    const scroll_listener = () => {
      Site.checkInfiniteScroll(vue, markers)
    }
    window.addEventListener('scroll', scroll_listener)
    return scroll_listener
  }
  /**
   *
   * @param {string} selector
   */
  static initNarrow(selector) {
    /** @type {HTMLTableElement[]} */
    //@ts-ignore
    const tables = document.querySelectorAll(selector + ' table.table')
    for (const table of tables) {
      if (table.tHead) {
        /** @type {string[]} */
        const titles = []
        const tr = table.tHead.rows[0]
        for (const c of tr.cells) {
          titles.push(c.textContent || '')
        }
        for (const tb of table.tBodies) {
          const tr = tb.rows[0]
          for (let i = 0; i < tr.cells.length; i++) {
            const c = tr.cells[i]
            if (titles[i]) {
              c.dataset.title = titles[i]
            }
          }
        }
      }
    }
  }
  static async optionalTranslate() {
    if (this.translateEnabled) {
      await this.translate()
    }
  }
  static optionalTranslateSelect() {
    if (this.translateEnabled) {
      $('#google_translate_element').show()
    }
  }
  /**
   * @param {string} p
   * @return {Promise<*>}
   */
  static async require_once(p) {
    if (!this.required[p]) {
      for (const s of document.querySelectorAll(
        'script:not([data-required-by])'
      )) {
        this.required[s.getAttribute('src')] = new Promise((resolve) =>
          resolve()
        )
        if (s.src) {
          //@ts-ignore
          s.dataset.requiredBy = 'page'
        }
      }
    }
    if (!this.required[p]) {
      this.required[p] = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = p
        script.dataset.requiredBy = 'site'
        document.body.appendChild(script)
        script.addEventListener('load', resolve)
        script.addEventListener('error', reject)
      })
    }
    return this.required[p]
  }
  /**
   *
   * @param {number} over_seconds
   */
  static showPage(over_seconds = 0) {
    if (over_seconds) {
      document.body.style.transition = `opacity ${over_seconds}s ease-in-out`
    }
    document.body.style.opacity = '1'
  }
  static async translate() {
    await this.require_once(
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
  }
}
/**
 * @type {{[src: string]: Promise<*>}}
 */
Site.required = {}

Site.Format = {
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   * @returns {?string}
   */
  date(d, options = { format: true, time: false, utc: false }) {
    return d && tdUtils.createDate(d, options)
  },
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   * @returns {?string}
   */
  dateString(ds, options = { format: true, time: false, utc: false }) {
    return ds && this.date(ds, options)
  },
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   * @returns {?string}
   */
  dateTime(d, options = { format: true, time: true, utc: false }) {
    return d && tdUtils.createDate(d, options)
  },
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   * @returns {?string}
   */
  dateTimeString(ds, options = { format: true, time: true, utc: false }) {
    return ds && this.dateTime(ds, options)
  },
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   * @returns {?string}
   */
   dateTimeStringFromNow(ds, options = { format: true, time: true, utc: false }) {
    return ds && tdUtils.getMoment(ds).fromNow();
  },
  /**
   *
   * @param {?number} bytes
   * @returns {?string} eg. "10MB"
   */
  GB(bytes) {
    if (bytes === null) return null
    if (bytes == Infinity) return translation.get('global_ublimited')
    return Math.round(bytes / 2 ** 30) + 'GB'
  },
  /**
   * @param {?number} bytes
   * @returns {?string} eg. "10MB"
   */
  GBorMB(bytes) {
    if (this.preferGB) {
      return this.GB(bytes && (bytes * 1024) / 1000)
    } else {
      return this.MB(bytes)
    }
  },
  /**
   *
   * @param {?number} bytes
   * @returns {?string} eg. "10MB"
   */
  MB(bytes) {
    if (bytes === null) return null
    if (bytes == Infinity) return translation.get('global_ublimited')
    return Math.round(bytes / 2 ** 20) + 'MB'
  },
  /**
   * @param {Date|string} d
   * @param {{ format: ?(boolean|string) }} options
   * @returns {string} eg. "January 2019"
   */
  month(d, options = { format: null }) {
    return (
      d &&
      tdUtils.createDate(d, options).format('MMMM YYYY')
    )
  },
  /**
   * @param {string} m eg. "2019-01"
   * @param {{ format: ?(boolean|string) }} options
   * @returns {string} eg. "January 2019"
   */
  monthString(m, options = { format: null }) {
    return this.month(m.substring(0, 7) + '-01T12:00:00Z', options)
  },
  /**
   *
   * @param {?number} n
   * @returns {?string} eg. "Unlimited"
   */
  number(n) {
    if (n === null) return null
    if (n == Infinity) return translation.get('global_ublimited')
    return `${n}`
  },
  /**
   *
   * @param {?number} months
   * @returns {?string} eg. "every year"
   */
  periodMonths(months) {
    if (months === null) return null
    if (months % 12) {
      if (months == 1) return translation.get('global_every_month')
      else return translation.sprintf(
        translation.get('global_every_number_months'),
        [String(months)]
    )
    } else {
      const y = months / 12
      if (y == 1) {
        return translation.get('global_every_year')
      } else {
        return translation.sprintf(
          translation.get('global_every_number_years'),
          [String(y)]
        )
      }
    }
  },
  /**
   * Formats number n like a price
   *
   * @param {number | string | null} n
   * @param {string} currency eg. "GBP"
   * @returns {string}
   */
  price(n, currency = 'GBP') {
    if (n === null) return translation.get('global_unknown')

    const currencyValue = (+n)
      .toLocaleString('en-GB', {
        style: 'currency',
        currency: currency,
      })
      .replace(
        /[.].+/,
        (Math.round(+n * 100) / 100).toFixed(2).replace(/^[^.]+/, '')
      )

    /**
     * If toLocaleString (it uses Intl.NumberFormat under the hood) could
     * not identify the symbol for the currency then we have to manually check
     * it. This currently handles BDT.
     */
    if (currencyValue.includes(currency)) {

      // If we have the symbol in our catalogue use it
      if (Catalogue && Catalogue.currencies.hasOwnProperty(currency)) {
        return currencyValue.replace(currency, Catalogue.currencies[
          currency
        ].symbol)
      }
    }

    return currencyValue
  },
  /**
   * Formats number n to just include the fraction component
   *
   * @param {number | string | null} n
   * @returns {string}
   */
  priceTail(n) {
    if (n === null) return translation.get('global_unknown')
    return Math.abs(+n % 1)
      .toFixed(2)
      .substring(1)
  },
  /**
   * @param {?(Date|string)} d
   * @param {{ format: ?(boolean|string) }} options
   * @returns {?string}
   */
  time(d, options = { format: true }) {
    return d && tdUtils.createTime(d, options)
  },
  /**
   * @param {?string} d
   * @param {{ format: ?(boolean|string) }} options
   * @returns {?string}
   */
  timeString(ds, options = { format: true }) {
    return ds && this.time(ds, options)
  },
  /**
   *
   * @param {?number} bytes
   * @returns {?string} eg. "10MB" or "576GB"
   */
  xB(bytes) {
    if (bytes === null) return null
    if (bytes == Infinity) return translation.get('global_ublimited')
    const BYTE_SIZES = ['B', 'KB', 'MB', 'GB', 'TB', 'EB']
    const power = Math.floor(Math.log(bytes) / Math.log(1024))
    return Math.round(bytes / 1024 ** power) + BYTE_SIZES[power]
  },
}
Site.Format.preferGB = false

Vue.directive('fade-page-until', {
  bind(el, binding) {
    Site.hidePage()
    setTimeout(() => Site.showPage(), 1000)
  },
  inserted(el, binding) {
    if (binding.value) {
      Site.showPage()
    }
  },
  update(el, binding) {
    if (binding.value && !binding.oldValue) {
      Site.showPage(0.2)
    }
  },
})

Vue.directive('fade-unless', {
  inserted(el, binding) {
    if (!binding.value) {
      el.style.opacity = '0'
    }
  },
  update(el, binding) {
    if (binding.value && !binding.oldValue) {
      $(el).fadeTo(200, 1)
    } else if (binding.oldValue && !binding.value) {
      $(el).fadeTo(200, 0)
    }
  },
})

Vue.directive('fade-until', {
  inserted(el, binding) {
    if (!binding.value) {
      el.style.opacity = '0'
    }
  },
  update(el, binding) {
    if (binding.value && !binding.oldValue) {
      $(el).fadeTo(200, 1)
    }
  },
})

function dateFilterBinding() {
  Vue.filter(
    'dateString',
    /**
     * Generate a date string
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
     *
     * @return {?string}
     */
    (date, options = { format: true, time: false, utc: false }) => {
      if (typeof date === 'string') {
        return Site.Format.dateString(date, options)
      }

      return Site.Format.date(date, options)
    }
  )

  Vue.filter(
    'dateTimeString',
    /**
     * Generate a date string with time included
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
     *
     * @return {?string}
     */
    (date, options = { format: true, time: true, utc: false }) => {
      if (typeof date === 'string') {
        return Site.Format.dateTimeString(date, options)
      }

      return Site.Format.dateTime(date, options)
    }
  )

  Vue.filter(
    'dateTimeStringFromNow',
    /**
     * Generate a date string with time included
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
     *
     * @return {?string}
     */
    (date, options = { format: true, time: true, utc: false }) => {
      if (typeof date === 'string') {
        return Site.Format.dateTimeStringFromNow(date, options)
      }

      return Site.Format.dateTime(date, options)
    }
  )

  Vue.filter(
    'dateStringUTC',
    /**
     * Generate a date string forcing UTC
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
     *
     * @return {?string}
     */
    (date, options = { format: true, time: false, utc: true }) => {
      if (typeof date === 'string') {
        return Site.Format.dateString(date, options)
      }

      return Site.Format.date(date, options)
    }
  )

  Vue.filter(
    'dateTimeStringUTC',
    /**
     * Generate a date string with time included forcing UTC
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
     *
     * @return {?string}
     */
    (date, options = { format: true, time: true, utc: true }) => {
      if (typeof date === 'string') {
        return Site.Format.dateTimeString(date, options)
      }

      return Site.Format.dateTime(date, options)
    }
  )

  Vue.filter(
    'timeString',
    /**
     * Generate a time string
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string) }} options
     *
     * @return {?string}
     */
    (date, options = { format: true }) => {
      if (typeof date === 'string') {
        return Site.Format.timeString(date, options)
      }

      return Site.Format.time(date, options)
    }
  )

  Vue.filter(
    'monthString',
    /**
     * Generate a month string (MMMM-YY)
     *
     * @param {string|Date} date
     * @param {{ format: ?(boolean|string) }} options
     *
     * @return {?string}
     */
    (date, options = { format: null }) => {
      if (typeof date === 'string') {
        return Site.Format.monthString(date, options)
      }

      return Site.Format.month(date, options)
    }
  )
}

dateFilterBinding()
